
import auth from '@/mixins/auth';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default auth.extend({
  name: 'FreeTrialBar',

  data: () => ({
    freeTrialBarDisplay: true,
    productsLoading: false
  }),

  computed: {
    appProductsDialog: {
      get(): any {
        return this.$store.state.app.appProductsDialog;
      },
      set: function (val: boolean) {
        this.setAppProductsDialog(val);
      }
    } as any,

    ...mapGetters({
      products: 'product/getProducts'
    }),

    freeTrialDaysRemaining(): string {
      const originalTrialStartDate = moment(
        new Date(this.getCurrentUser.organization.created_at)
      );
      const freeTrialEndDate = originalTrialStartDate.add({
        days: this.freeTrialDayLength
      });

      const daysRemaining = freeTrialEndDate.diff(Date.now(), 'days') + 1;

      return `in ${daysRemaining} ${daysRemaining > 1 ? 'days' : 'day'}`;
    }
  },

  mounted() {
    if (localStorage.getItem('free-trial-bar-dismissed') == 'true') {
      this.freeTrialBarDisplay = false;
    }
  },

  methods: {
    ...mapActions({
      fetchProducts: 'product/fetchProducts',
      setAppProductsDialog: 'app/setAppProductsDialog'
    }),

    onDismiss() {
      localStorage.setItem('free-trial-bar-dismissed', 'true');
      this.freeTrialBarDisplay = false;
    },

    openProductsDialog() {
      if (this.products.length == 0) {
        this.productsLoading = true;
        this.fetchProducts().finally(() => {
          this.productsLoading = false;
        });
      }
      this.appProductsDialog = true;
    }
  }
});
